@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300&display=swap');

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

html {
	font-size: 14px;
	scroll-behavior: smooth; }

code {
	font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif'; }

h1 {
	font-size: 2.1rem !important;
	font-weight: 200 !important; }
h2 {
	font-weight: 600 !important;
	font-size: 1.3rem !important; }
h3 {
	font-size: 1.4rem !important;
	font-weight: 600 !important; }

h4 {
	font-size: 1.25rem !important;
	font-weight: 600 !important; }

h5 {
	font-weight: 600 !important; }

.root {
	height: 100vh; }
