@import '../../assets/sass/colors';

.spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 510;

    .spinner {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        z-index: 150;
        color: $green; } }
