$white: #fafafa;
$green: #63a118;
$light-green: #e8f1d9;
$lighter-green: #f6f9f0;
$orange: #f59a23;
$light-orange: #fde2c7;
$gray: #515953;
$light-gray: #d1d3d4;
$lighter-gray: #efefef;
$red: #f44336;
