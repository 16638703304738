@import '../../assets/sass/colors';

.btn {
    min-width: max-content !important;
    padding: 6px 10px !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    text-transform: none !important;
    line-height: 1.5 !important;

    &__icon {
        margin-right: 4px !important;
        margin-top: 2px !important; }

    &--contained-primary {
        background-color: $green !important;

        &:hover {
            background-color: $green !important; } }

    &--contained-secondary {
        background-color: $orange !important;

        &:hover {
            background-color: $orange !important; } }

    &--contained-error {
        background-color: $red !important;

        &:hover {
            background-color: $red !important; } }

    &--text-primary {
        color: $green;

        &:hover {
            background-color: rgba(232, 241, 217, .4) !important; } }

    &--text-secondary {
        color: $orange !important; }

    &--outlined-primary {
        color: $green !important;
        border-color: $green !important;

        &:hover {
            border-color: $green !important; } }

    &--outlined-secondary {
        color: $orange !important;
        border-color: $orange !important; }

    &--outlined-error {
        color: $red !important;
        border-color: $red !important; }

    &--disabled {}

    &--small {
        height: 30px !important;
        line-height: 1.3rem !important; } }

.btn.MuiButton-contained.Mui-disabled {
    color: white;
    background-color: rgb(150, 150, 150) !important;
    opacity: .7 !important; }
